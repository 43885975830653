.fullwidth {
  width: 100%;
}

.fullheight {
  height: 100%;
}

html#kiosk,
#kiosk body {
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100%;
  height: 100%;
  position: relative;
}

#slidea-advertisement {
  height: 200px !important;
}

#slidea-spot {
  color: #fff;
  height: 100% !important;
}

#slidea-spot .layer {
  opacity: 0;
}

#slidea-spot h1 {
  font-size: 10em;
}

.top-panel {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
}

#kiosk-wrapper {
  overflow: hidden;
}

.btn-menu {
  z-index: 102;
  top: 15px;
  right: 15px;
  position: absolute;
  margin: 0 0;
  color: white;
  background: #1a1a1a;
  border: 1px solid #0d0d0d;
  width: 50px;
  height: 50px;
  padding: 0;
  line-height: 0;
}

.btn-menu:hover {
  background: white;
}

.btn-menu:hover .hamburger.active .hamburger-inner {
  background: transparent;
}

.btn-menu:hover .hamburger-inner {
  background: black;
}

.btn-menu:hover .hamburger-inner::before, .btn-menu:hover .hamburger-inner::after {
  background: black;
}

.overlay-menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  height: 100%;
  width: 100%;
  height: 0;
  opacity: 0;
  background: rgba(0, 0, 0, 0.8);
  transition: all 1s ease;
}

.overlay-menu.active {
  height: 100%;
  opacity: 1;
}

.overlay-menu .overlay-menu-content {
  position: absolute;
  left: 50%;
  top: 50%;
  display: block;
  transform: translateX(-50%) translateY(-50%);
  font-size: 32px;
}

.overlay-menu .overlay-menu-content ul li {
  margin-bottom: 20px;
}

.overlay-menu .overlay-menu-content ul li a {
  color: #fff;
}

.notification {
  margin: 0;
  padding: 20px 20px;
  display: block;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.5);
  color: #f2f2f2;
}

.notification .close-notification {
  float: right;
  border-radius: 10%;
  padding: 13px 10px 16px;
  display: block;
  line-height: 0;
  background: rgba(255, 255, 255, 0.1);
  transition: all 0.5s ease;
}

.notification .close-notification:hover {
  color: black;
  cursor: pointer;
  background: white;
}

.notification p {
  margin: 0;
}

.advertisement {
  height: 200px;
  width: 100%;
  display: block;
  position: relative;
}

.slider-wrapper {
  width: 100%;
  position: relative;
  display: block;
  height: calc(100% - 200px);
}

.slider-left,
.slider-right {
  background: #1a1a1a;
  position: absolute;
  width: 80px;
  height: 100%;
  display: block;
  top: 0;
  cursor: pointer;
}

.slider-left .slider-control-icon,
.slider-right .slider-control-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  font-size: 32px;
  color: #777777;
}

.slider-left:hover .slider-hover,
.slider-right:hover .slider-hover {
  opacity: 1;
}

.slider-left .slider-hover,
.slider-right .slider-hover {
  z-index: 1;
  transition: all 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background: #111;
}

.slider-left .slider-hover .slider-control-icon,
.slider-right .slider-hover .slider-control-icon {
  color: #f2f2f2 !important;
}

.slider-left {
  left: 0;
}

.slider-right {
  right: 0;
}

.slider-center {
  position: relative;
  margin: 0 auto;
  height: 100%;
  width: calc(100% - 160px);
}

.hamburger {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
  z-index: 2;
  margin-top: 13px;
}

.hamburger .hamburger-inner {
  display: block;
  width: 30px;
  height: 4px;
  margin-top: 8px;
  background: white;
  transition: all 0.4s ease-in-out;
}

.hamburger .hamburger-inner::before, .hamburger .hamburger-inner::after {
  display: block;
  position: absolute;
  content: "";
  z-index: 1;
  width: 30px;
  height: 4px;
  background: white;
  transition: all 0.4s ease-in-out;
}

.hamburger .hamburger-inner::before {
  top: 0;
}

.hamburger .hamburger-inner::after {
  top: 16px;
}

.hamburger.active .hamburger-inner {
  background: none;
}

.hamburger.active .hamburger-inner::before, .hamburger.active .hamburger-inner::after {
  top: 8px;
}

.hamburger.active .hamburger-inner::before {
  transform: rotate(-45deg);
}

.hamburger.active .hamburger-inner::after {
  transform: rotate(45deg);
}
